import React, { useEffect, useState } from "react";
import {
  Button, 
  Col, 
  Input, 
  Tabs, 
  Select, 
  Checkbox, 
  Form, 
  InputNumber
} from "antd";
import Widget from "components/Widget";
import Map from "../../Map/Map";
import { useAuth } from "../../../context/auth-context";
import { useProfile } from "../../../context/profile/useProfile";
import PaymentMethods from "./PaymentMethods";
import moment from "moment";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const { Option } = Select;

const hoursOptions = [
  "12:00 AM",
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 AM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM",
];

const About = () => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1");
  const { isAdmin } = useAuth();
  const [coordinates, setCoordinates] = useState({
    lat: 10.482328027687851,
    lng: -66.72502382728119,
  });
  const [accountDataTable, setAccountDataTable] = useState({
    accounts_bs: [],
    mobile_payment: [],
    accounts_d: [],
    paypal: [],
  });
  const {
    storeLocation,
    isLoading,
    updateProfile,
    selectedProfile,
    updateLocation,
    scheduleData,
    onChangeSchedule,
    updateSchedule,
    accountsData,
    updateCommissionStore
  } = useProfile();

  const {
    email,
    description,
    name,
    phone,
    commission_percentage,
    contact_name,
    contact_phone,
    contact_job,
    doc_type,
    dispatch_code,
    doc_number,
  } = selectedProfile ? selectedProfile : {};

  const onSave = async () => {
    if(isAdmin){
      const fieldsValue = await form.getFieldsValue();
      updateCommissionStore({commission_percentage:(fieldsValue.commission_percentage/100)})
    } else {
      await form.validateFields().then((fields) => {
        updateProfile(fields);
      });
    }
  };

  const onSaveLocation = async () => {
    updateLocation({ latitude: coordinates.lat, longitude: coordinates.lng });
  };

  useEffect(() => {
    form.setFieldsValue({
      email,
      name,
      description,
      phone,
      dispatch_code,
      commission_percentage: Number(commission_percentage)*100,
      contact_name,
      contact_phone,
      contact_job,
      doc_type,
      doc_number,
    });
  }, [selectedProfile]);

  useEffect(()=>{
    if(accountsData.results && accountsData.results.length > 0){
      const transformData = () => {
        const accountItems = {
          accounts_bs: [],
          mobile_payment: [],
          accounts_d: [],
          paypal: [],
        };
        accountsData.results.forEach(item => {
          let newItem;
          switch (item.type) {
            case 'VES':
              newItem = {
                id: item.id,
                number: item.number,
                holder_name: item.holder_name,
                doc_type: item.doc_type,
                doc_number: item.doc_number,
                is_preferential: item.is_preferential,
                phone: item.phone,
                bank_name: item.bank_name,
              };
              if(item.phone){
                accountItems.mobile_payment.push(newItem);
              } else {
                accountItems.accounts_bs.push(newItem);
              }
              break;
            case 'PAYPAL':
              newItem = {
                id: item.id,
                phone: item.phone,
                holder_name: item.holder_name,
                email: '',
                is_preferential: item.is_preferential,
              };
              accountItems.paypal.push(newItem);
              break;
            case 'USD':
              newItem = {
                id: item.id,
                number: item.number,
                holder_name: item.holder_name,
                doc_type: item.doc_type,
                doc_number: item.doc_number,
                is_preferential: item.is_preferential,
                bank_name: item.bank_name,
              };
              accountItems.accounts_d.push(newItem);
              break;
            default:
              break;
          }
        });
        return accountItems;
      };
      setAccountDataTable(transformData)
    }
  },[accountsData])

  const onSaveSchedule = () => {
    updateSchedule();
  };

  const onMapClick = (vals) => {
    setCoordinates(vals);
  };

  const onSelectDoc = (value) => {
    form.setFieldsValue({ doc_type: value })
  };

  useEffect(()=>{
    if(storeLocation.latitude){
      setCoordinates({lat: storeLocation.latitude, lng: storeLocation.longitude})
    }
  },[storeLocation])

  return (
    <Widget
      title="About"
      styleName="gx-card-tabs gx-card-tabs-right gx-card-profile"
    >
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={(activeKey) => setActiveTab(activeKey)}
      >
        <TabPane tab="Datos" key="1" style>
          <div className="gx-mb-2">
            <Form
              form={form}
              layout="vertical"
            >
              <div
                style={{
                  gap: "50px",
                  margin: "0px 15px",
                  display: "flex",
                }}
                className=""
              >
                <Col style={{ flexGrow: 0.8, flexBasis: 0 }}>
                  <FormItem
                    label="Nombre del Bar"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Introduzca el nombre del establecimiento.",
                      },
                    ]}
                  >
                    <Input placeholder="Nombre del bar" disabled={isAdmin} />
                  </FormItem>
                  <FormItem label="Teléfono del bar" required name="phone">
                    <Input
                      placeholder="Número de teléfono"
                      disabled={isAdmin}
                    />
                  </FormItem>
                  <FormItem 
                    label="Tipo de documento" 
                    required 
                    name="doc_type"
                  >
                    <Select
                      placeholder="Tipo de documento"
                      onChange={(value) => onSelectDoc(value)}
                      disabled={isAdmin}
                      options={[
                        {label:"CI", value:"CI"},
                        {label:"RIF", value:"RIF"},
                      ]}
                    />
                  </FormItem>
                  <FormItem 
                    label="Comisión del bar" 
                    required 
                    name="commission_percentage"
                  >
                    <InputNumber 
                      formatter={value => `${value} %`}
                      parser={value => value.replace('%', '')}
                      placeholder="Comisión del bar" 
                      min={0}
                      disabled={!isAdmin}
                    />
                  </FormItem>
                  <FormItem 
                    label="Codigo de despacho" 
                    required 
                    name="dispatch_code"
                  >
                    <InputNumber 
                      placeholder="Codigo de despacho" 
                      disabled={true}
                    />
                  </FormItem>
                </Col>
                <Col
                  style={{ marginLeft: "auto", flexGrow: 1.2, flexBasis: 0 }}
                >
                  <FormItem
                    label="Descripción"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Introduzca una descripción.",
                      },
                    ]}
                  >
                    <Input.TextArea
                      autoComplete="off"
                      rows={5}
                      placeholder="Descripción del bar"
                      disabled={isAdmin}
                    />
                  </FormItem>
                  <FormItem 
                    label="Número de documento" 
                    required 
                    name="doc_number"
                  >
                    <Input 
                      placeholder="Número de documento" 
                      disabled={isAdmin}
                    />
                  </FormItem>
                </Col>
              </div>
              <div
                style={{
                  margin: "0 0 15px 15px",
                  display: "flex",
                  fontWeight:500,
                }}
              >
                Datos del encargado
              </div>
              <div
                style={{
                  gap: "50px",
                  margin: "0px 15px",
                  display: "flex",
                }}
                className=""
              >
                <Col style={{ flexGrow: 0.8, flexBasis: 0 }}>
                  <FormItem
                    label="Nombre del Encargado"
                    name="contact_name"
                    rules={[
                      {
                        required: true,
                        message: "Introduzca el nombre del encargado.",
                      },
                    ]}
                  >
                    <Input placeholder="Nombre del encargado" disabled={isAdmin} />
                  </FormItem>
                  <FormItem 
                    label="Teléfono del encargado" 
                    rules={[
                      {
                        required: true,
                        message: "Introduzca el número de teléfono del encargado.",
                      },
                    ]}
                    name="contact_phone"
                  >
                    <Input
                      placeholder="Número de teléfono"
                      disabled={isAdmin}
                    />
                  </FormItem>
                </Col>
                <Col
                  style={{ marginLeft: "auto", flexGrow: 1.2, flexBasis: 0 }}
                >
                  <FormItem
                    name="email"
                    label="Correo del encargado"
                    rules={[
                      {
                        type: "email",
                        message: "El correo electrónico no es valido.",
                      },
                      {
                        required: true,
                        message: "Introduzca su correo electrónico.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Correo electrónico"
                      disabled={isAdmin}
                    />
                  </FormItem>
                  <FormItem
                    label="Cargo"
                    name="contact_job"
                    rules={[
                      {
                        required: true,
                        message: "Introduzca el cargo de la persona encargada",
                      },
                    ]}
                  >
                    <Input placeholder="Cargo" disabled={isAdmin} />
                  </FormItem>
                </Col>
              </div>
            </Form>
            <Button
              style={{ margin: "10px auto auto 16px" }}
              onClick={onSave}
              loading={isLoading}
            >
              {isAdmin ? "Editar comisión" : "Guardar"}
            </Button>
          </div>
        </TabPane>
        <TabPane tab="Ubicación" key="2" style={{ paddig: 0 }}>
          <div className="" style={{ height: 544 }}>
            <Map
              isLoading={isLoading}
              disabled={isAdmin}
              onPinLocation={onMapClick}
              pinCoordinates={{
                lat: storeLocation.latitude,
                lng: storeLocation.longitude,
              }}
            />
          </div>

          {!isAdmin && (
            <Button
              style={{ margin: "10px auto auto auto" }}
              onClick={onSaveLocation}
              isLoading={isLoading}
            >
              Guardar
            </Button>
          )}
        </TabPane>
        <TabPane tab="Horarios" key="3" style={{ paddig: 0 }}>
          <div style={{ padding: 30 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
                minWidth: 250,
              }}
            >
              {[
                "Lunes",
                "Martes",
                "Miercoles",
                "Jueves",
                "Viernes",
                "Sabado",
                "Domingo",
              ].map((day, idx) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    justifyContent: "center",
                  }}
                  key={idx}
                >
                  <div
                    style={{
                      flex: "1 1 0",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span>{day}</span>
                  </div>
                  <FormItem
                    required
                    style={{
                      flex: "1 1 0",
                      margin: 0,
                    }}
                  >
                    <Input.Group
                      compact
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Select
                        onChange={(value) =>
                          onChangeSchedule({ value, day: idx, openHour: true })
                        }
                        disabled={isAdmin}
                        value={
                          scheduleData[idx]
                            ? moment(scheduleData[idx].open_hour, "hh").format(
                                "hh:mm A"
                              )
                            : "9:00 AM"
                        }
                      >
                        {hoursOptions.map((hour, index) => (
                          <Option value={`${hour}`} key={index} >{hour}</Option>
                        ))}
                      </Select>
                      <Input
                        className="site-input-split"
                        style={{
                          width: 30,
                          borderLeft: 0,
                          borderRight: 0,
                          pointerEvents: "none",
                        }}
                        placeholder="~"
                        disabled
                      />
                      <Select
                        defaultValue="11:00 PM"
                        disabled={isAdmin}
                        onChange={(value) =>
                          onChangeSchedule({ value, day: idx, openHour: false })
                        }
                        value={
                          scheduleData[idx]
                            ? moment(scheduleData[idx].close_hour, "hh").format(
                                "hh:mm A"
                              )
                            : "9:00 AM"
                        }
                        style={{ minWidth: 100 }}
                      >
                        {hoursOptions.map((hour, idx) => (
                          <Option value={`${hour}`} key={idx}>
                            {hour}
                          </Option>
                        ))}
                      </Select>
                    </Input.Group>
                  </FormItem>
                  <div
                    style={{
                      flex: "1 1 0",

                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    <Checkbox
                      disabled={isAdmin}
                      onChange={({ target }) =>
                        onChangeSchedule({
                          day: idx,
                          closed: target.checked,
                        })
                      }
                      checked={scheduleData[idx] && scheduleData[idx].closed}
                    />
                    <span style={{ marginLeft: "1rem", alignSelf: "center", marginTop:5 }}>Cerrado</span>
                  </div>
                </div>
              ))}
              {!isAdmin && (
                <Button
                  style={{ margin: "10px auto auto auto" }}
                  onClick={onSaveSchedule}
                  isLoading={isLoading}
                >
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tab="Información bancaria" key="4">
          <PaymentMethods
            data={accountDataTable}
          />
        </TabPane>
      </Tabs>
    </Widget>
  );
};

export default About;
