import React, { useState } from "react";
import {
  Button, 
  Input, 
  Tabs, 
  Select, 
  Checkbox, 
  Form, 
  Table, 
  Tooltip,  
  Col,
  Row
} from "antd";
import { useAuth } from "../../../context/auth-context";
import { DeleteOutlined } from "@ant-design/icons";
import { useProfile } from "../../../context/profile/useProfile";
import DeleteModal from "../../Modal/DeleteModal";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

const PaymentMethods = ({data}) => {
  const [form] = Form.useForm();
  const [formMobile] = Form.useForm();
  const [formDollar] = Form.useForm();
  const [formPaypal] = Form.useForm();
  const [activeTabPayment, setActiveTabPayment] = useState("1");
  const [loading, setLoading] = useState(false);
  const [bsAccount, setBsAccount] = useState({
    id:"",
    doc_type:"",
    doc_number:"",
    number:"",
    holder_name:"",
    is_preferential: false,
  })
  const [mobileData, setMobileData] = useState({
    id:"",
    phone:"",
    holder_name:"",
    doc_type:"",
    doc_number:"",
    is_preferential: false,
  })
  const [dollarAccount, setDollarAccount] = useState({
    id:"",
    doc_type:"",
    doc_number:"",
    number:"",
    holder_name:"",
    is_preferential: false,
  })
  const [paypal, setPaypal] = useState({
    id:"",
    phone:"",
    holder_name:"",
    email:"",
    is_preferential: false,
  })
  const [showForm, setShowForm] = useState({
    bs: false,
    mobile: false,
    USD: false,
    PAYPAL: false,
  });

  const { deleteAccount, createAccount } = useProfile();

  const { isAdmin } = useAuth();

  const onSave = async (type) => {
    if(type === "mobile"){
        await formMobile.validateFields().then((fields) => {
          createAccount({...fields, type:"VES", number: fields.phone})
        });
    } else if ( type === "PAYPAL"){
        await formPaypal.validateFields().then((fields) => {
          createAccount({...fields, type:"PAYPAL"})
        });
    } else if (type === "USD"){
        await formDollar.validateFields().then((fields) => {
          createAccount({...fields, type:"USD"})
        });
    } else {
        await form.validateFields().then((fields) => {
          createAccount({...fields, type:"VES"})
        });
    }
  };

  const onCreateAccount = (type) => {
    setShowForm((prev) => ({
      ...prev,
      [type]: true,
    }));
    const initialAccount = {
        id:"",
        doc_type:"",
        doc_number:"",
        number:"",
        holder_name:"",
        is_preferential: false,
    } 
    const initialMobile = {
        id:"",
        phone:"",
        holder_name:"",
        doc_type:"",
        doc_number:"",
        is_preferential: false,
    }
    const initialPaypal = {
        id:"",
        phone:"",
        holder_name:"",
        email:"",
        is_preferential: false,
    }
    if(type === "mobile"){
        formMobile.setFieldsValue(initialMobile);
        setMobileData(initialMobile)
    } else if ( type === "PAYPAL"){
        formPaypal.setFieldsValue(initialPaypal);
        setPaypal(initialPaypal)
    } else if (type === "USD"){
        formDollar.setFieldsValue(initialAccount);
        setDollarAccount(initialAccount)
    } else {
        form.setFieldsValue(initialAccount);
        setBsAccount(initialAccount)
    }
    setLoading(false);
  };

 /*  const editTransfer = (item, type)=> {
    setShowForm((prev) => ({
      ...prev,
      [type]: true,
    }));
    if(type === "mobile"){
        formMobile.setFieldsValue(item);
        setMobileData(item)
    } else if ( type === "PAYPAL"){
        formPaypal.setFieldsValue(item);
        setPaypal(item)
    } else if (type === "USD"){
        formDollar.setFieldsValue(item);
        setDollarAccount(item)
    } else {
        form.setFieldsValue(item);
        setBsAccount(item)
    }
  }; */

  const transfersColumns = [
    {
      title: "Cuenta preferencial",
      dataIndex: "is_preferential",
      key: "is_preferential",
      align: "center",
      render: (_, record) => (
            <Checkbox checked={record.is_preferential} />
      ),
    },
    {
      title: "Nombre de titular",
      dataIndex: "holder_name",
      key: "holder_name",
      align: "center",
    },
    {
      title: "Tipo de documento",
      dataIndex: "doc_type",
      key: "doc_type",
      align: "center",
    },
    {
      title: "Número de documento",
      dataIndex: "doc_number",
      key: "doc_number",
      align: "center",
    },
    {
      title: "Número de cuenta",
      dataIndex: "number",
      key: "number",
      align: "center",
    },
    {
      title: "Nombre de banco",
      dataIndex: "bank_name",
      key: "bank_name",
      align: "center",
    },
    {
      title: "Acciones",
      key: "action",
      width: 150,
      align: "center",
      style: { justifyContent: "center" },
      render: (_, record) => (
        <>
          {/* <Tooltip title="Editar">
            <EditOutlined
              className="mr-2"
              style={{ fontSize: 20, cursor: "pointer", marginRight: "10px" }}
              onClick={() => {editTransfer(record, "bs")}}
            />
          </Tooltip> */}
          <Tooltip title="Eliminar">
            <DeleteOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() =>
                DeleteModal(record, "esta cuenta", () =>
                  deleteAccount({accountId:record.id})
                )
              }
            />
          </Tooltip> 
        </>
      ),
    },
  ]

  const dollarColumns = [
    {
      title: "Cuenta preferencial",
      dataIndex: "is_preferential",
      key: "is_preferential",
      align: "center",
      render: (_, record) => (
            <Checkbox checked={record.is_preferential} />
      ),
    },
    {
      title: "Nombre de titular",
      dataIndex: "holder_name",
      key: "holder_name",
      align: "center",
    },
    {
      title: "Tipo de documento",
      dataIndex: "doc_type",
      key: "doc_type",
      align: "center",
    },
    {
      title: "Número de documento",
      dataIndex: "doc_number",
      key: "doc_number",
      align: "center",
    },
    {
      title: "Número de cuenta",
      dataIndex: "number",
      key: "number",
      align: "center",
    },
    {
      title: "Nombre de banco",
      dataIndex: "bank_name",
      key: "bank_name",
      align: "center",
    },
    {
      title: "Acciones",
      key: "action",
      width: 150,
      align: "center",
      style: { justifyContent: "center" },
      render: (_, record) => (
        <>
          {/* <Tooltip title="Editar">
            <EditOutlined
              className="mr-2"
              style={{ fontSize: 20, cursor: "pointer", marginRight: "10px" }}
              onClick={() => {editTransfer(record, "USD")}}
            />
          </Tooltip> */} 
          <Tooltip title="Eliminar">
            <DeleteOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() =>
                DeleteModal(record, "esta cuenta", () =>
                  deleteAccount({accountId:record.id})
                )
              }
            />
          </Tooltip> 
        </>
      ),
    },
  ]

  const mobileColumns = [
    {
        title: "Cuenta preferencial",
        dataIndex: "is_preferential",
        key: "is_preferential",
        align: "center",
        render: (_, record) => (
              <Checkbox checked={record.is_preferential} />
        ),
      },
      {
        title: "Nombre de titular",
        dataIndex: "holder_name",
        key: "holder_name",
        align: "center",
      },
      {
        title: "Tipo de documento",
        dataIndex: "doc_type",
        key: "doc_type",
        align: "center",
      },
      {
        title: "Número de documento",
        dataIndex: "doc_number",
        key: "doc_number",
        align: "center",
      },
      {
        title: "Número de teléfono",
        dataIndex: "phone",
        key: "phone",
        align: "center",
      },
      {
        title: "Nombre de banco",
        dataIndex: "bank_name",
        key: "bank_name",
        align: "center",
      },
      {
        title: "Acciones",
        key: "action",
        width: 150,
        align: "center",
        style: { justifyContent: "center" },
        render: (_, record) => (
          <>
            {/* <Tooltip title="Editar">
              <EditOutlined
                className="mr-2"
                style={{ fontSize: 20, cursor: "pointer", marginRight: "10px" }}
                onClick={() => {editTransfer(record, "mobile")}}
              />
            </Tooltip> */} 
            <Tooltip title="Eliminar">
              <DeleteOutlined
                style={{ fontSize: 20, cursor: "pointer" }}
                onClick={() =>
                  DeleteModal(record, "esta cuenta", () =>
                    deleteAccount({accountId:record.id})
                  )
                }
              />
            </Tooltip> 
          </>
        ),
      },
  ]

  const paypalColumns = [
    {
        title: "Cuenta preferencial",
        dataIndex: "is_preferential",
        key: "is_preferential",
        align: "center",
        render: (_, record) => (
              <Checkbox checked={record.is_preferential} />
        ),
      },
      {
        title: "Usuario",
        dataIndex: "holder_name",
        key: "holder_name",
        align: "center",
      },
      {
        title: "Correo",
        dataIndex: "email",
        key: "email",
        align: "center",
      },
      {
        title: "Número de teléfono",
        dataIndex: "phone",
        key: "phone",
        align: "center",
      },
      {
        title: "Acciones",
        key: "action",
        width: 150,
        align: "center",
        style: { justifyContent: "center" },
        render: (_, record) => (
          <>
            {/* <Tooltip title="Editar">
              <EditOutlined
                className="mr-2"
                style={{ fontSize: 20, cursor: "pointer", marginRight: "10px" }}
                onClick={() => {editTransfer(record, "PAYPAL")}}
              />
            </Tooltip> */} 
            <Tooltip title="Eliminar">
              <DeleteOutlined
                style={{ fontSize: 20, cursor: "pointer" }}
                onClick={() =>
                  DeleteModal(record, "esta cuenta", () =>
                    deleteAccount({accountId:record.id})
                  )
                }
              />
            </Tooltip> 
          </>
        ),
      },
  ]

  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      size={"small"}
      activeKey={activeTabPayment}
      onChange={(activeKeyPayment) => setActiveTabPayment(activeKeyPayment)}
      style={{marginTop: 0}}
    >
      <TabPane tab="Transferencia en Bs" key="1" style={{ paddig: 0 }}>
        <div>
          {showForm.bs && (
              <>
                <Form
                  form={form}
                  className=""
                  layout="vertical"
                  initialValues={bsAccount}
                >
                    <Row className="gx-flex-row" style={{marginLeft:-16, marginRight:-16}}>
                        <Col md={12} sm={24}>
                            <FormItem
                              name="number"
                              label="Número de cuenta"
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Introduzca el número de cuenta",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Número de cuenta"
                                disabled={isAdmin}
                                maxLength={20}
                              />
                            </FormItem>
                            <Row className="gx-flex-row" style={{marginLeft:-15, marginRight:-16}}>
                                <Col md={8} sm={24}>
                                    <FormItem
                                      label="Documento"
                                      name="doc_type"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Seleccione el tipo de documento",
                                        },
                                      ]}
                                    >
                                      <Select
                                        defaultValue="RIF"
                                        disabled={isAdmin}
                                        onChange={(value) =>
                                          form.setFieldsValue({ doc_type: value })
                                        }
                                        options={[
                                          { value: "RIF", label: "RIF" },
                                          { value: "CI", label: "CI" },
                                        ]}
                                      />
                                    </FormItem>
                                </Col>
                                <Col md={16} sm={24}>
                                    <FormItem
                                      label="Número de documento"
                                      name="doc_number"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Introduzca el número de documento",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Número de documento" disabled={isAdmin} />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} sm={24}>
                            <FormItem
                              label="Nombre del titular"
                              name="holder_name"
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Introduzca el nombre del titular",
                                },
                              ]}
                            >
                              <Input placeholder="Nombre del titular" disabled={isAdmin} />
                            </FormItem>
                            <Row className="gx-flex-row" style={{marginLeft:-15, marginRight:-16}}>
                              <Col md={16} sm={24}>
                                <FormItem
                                  label="Banco"
                                  name="bank_name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Introduzca el nombre del banco",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Nombre de banco" disabled={isAdmin} />
                                </FormItem>
                              </Col>
                              <Col md={8} sm={24}>
                                <FormItem 
                                  name="is_preferential" 
                                  label="Preferencial"
                                >
                                  <Checkbox 
                                    disabled={isAdmin} 
                                    defaultChecked={form.getFieldValue('is_preferential')}
                                    onChange={(e) => 
                                      form.setFieldsValue({ is_preferential: e.target.checked })
                                    }
                                  ></Checkbox>
                                </FormItem>
                              </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                      
                {!isAdmin && (
                  <Button style={{}} onClick={()=>onSave("bs")} isLoading={loading}>
                    {bsAccount.id ? "Editar" : "Guardar"}
                  </Button>
                )}
              </>
          )}
          <Table
            className="gx-table-responsive"
            columns={transfersColumns.filter(col => !isAdmin || col.key !== 'action')}
            dataSource={data.accounts_bs}
            scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
            pagination={false}
          />
          {!isAdmin && (
            <div className="gx-d-flex gx-justify-content-end">
              <Button 
                onClick={()=> onCreateAccount("bs")} 
                isLoading={loading}
                className="gx-mt-2"
              >
                Añadir cuenta
              </Button>
            </div>
          )}
        </div>
      </TabPane>
      <TabPane tab="Pago móvil" key="2" style={{ paddig: 0 }}>
        <div>
          {showForm.mobile && (
              <>
                <Form
                  form={formMobile}
                  className=""
                  layout="vertical"
                  initialValues={mobileData}
                >
                    <Row className="gx-flex-row" style={{marginLeft:-16, marginRight:-16}}>
                        <Col md={12} sm={24}>
                            <FormItem
                              name="phone"
                              label="Número de teléfono"
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Introduzca el número de teléfono",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Número de teléfono"
                                disabled={isAdmin}
                                maxLength={20}
                              />
                            </FormItem>
                            <Row className="gx-flex-row" style={{marginLeft:-15, marginRight:-16}}>
                                <Col md={8} sm={24}>
                                    <FormItem
                                      label="Documento"
                                      name="doc_type"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Introduzca el tipo de documento",
                                        },
                                      ]}
                                    >
                                      <Select
                                        defaultValue="RIF"
                                        disabled={isAdmin}
                                        onChange={(value) =>
                                          formMobile.setFieldsValue({ doc_type: value })
                                        }
                                        options={[
                                          { value: "RIF", label: "RIF" },
                                          { value: "CI", label: "CI" },
                                        ]}
                                      />
                                    </FormItem>
                                </Col>
                                <Col md={16} sm={24}>
                                    <FormItem
                                      label="Número de documento"
                                      name="doc_number"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Introduzca el número de documento",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Número de documento" disabled={isAdmin} />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} sm={24}>
                            <FormItem
                              label="Nombre del titular"
                              name="holder_name"
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Introduzca el nombre del titular",
                                },
                              ]}
                            >
                              <Input placeholder="Nombre del titular" disabled={isAdmin} />
                            </FormItem>
                            <Row className="gx-flex-row" style={{marginLeft:-15, marginRight:-16}}>
                              <Col md={16} sm={24}>
                                <FormItem
                                  label="Banco"
                                  name="bank_name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Introduzca el nombre del banco",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Nombre de banco" disabled={isAdmin} />
                                </FormItem>
                              </Col>
                              <Col md={8} sm={24}>
                                <FormItem 
                                  name="is_preferential" 
                                  label="Preferencial"
                                >
                                  <Checkbox 
                                    disabled={isAdmin} 
                                    defaultChecked={formMobile.getFieldValue('is_preferential')}
                                    onChange={(e) => 
                                      formMobile.setFieldsValue({ is_preferential: e.target.checked })
                                    }
                                  ></Checkbox>
                                </FormItem>
                              </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                      
                {!isAdmin && (
                  <Button style={{}} onClick={()=>onSave("mobile")} isLoading={loading}>
                    {mobileData.id ? "Editar" : "Guardar"}
                  </Button>
                )}
              </>
          )}
          <Table
            className="gx-table-responsive"
            columns={mobileColumns.filter(col => !isAdmin || col.key !== 'action')}
            dataSource={data.mobile_payment}
            scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
            pagination={false}
          />
          {!isAdmin && (
            <div className="gx-d-flex gx-justify-content-end">
              <Button 
                onClick={()=> onCreateAccount("mobile")} 
                isLoading={loading}
                className="gx-mt-2"
              >
                Añadir cuenta
              </Button>
            </div>
          )}
        </div>
      </TabPane>
      <TabPane tab="Cuenta en $" key="3" style={{ paddig: 0 }}>
        <div>
            {showForm.USD && (
              <>
                <Form
                  form={formDollar}
                  className=""
                  layout="vertical"
                  initialValues={dollarAccount}
                >
                    <Row className="gx-flex-row" style={{marginLeft:-16, marginRight:-16}}>
                        <Col md={12} sm={24}>
                            <FormItem
                              name="number"
                              label="Número de cuenta"
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Introduzca el número de cuenta",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Número de cuenta"
                                disabled={isAdmin}
                                maxLength={20}
                              />
                            </FormItem>
                            <Row className="gx-flex-row" style={{marginLeft:-15, marginRight:-16}}>
                                <Col md={8} sm={24}>
                                    <FormItem
                                      label="Documento"
                                      name="doc_type"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Introduzca el tipo de documento",
                                        },
                                      ]}
                                    >
                                      <Select
                                        defaultValue="RIF"
                                        disabled={isAdmin}
                                        onChange={(value) =>
                                          formDollar.setFieldsValue({ doc_type: value })
                                        }
                                        options={[
                                          { value: "RIF", label: "RIF" },
                                          { value: "CI", label: "CI" },
                                        ]}
                                      />
                                    </FormItem>
                                </Col>
                                <Col md={16} sm={24}>
                                    <FormItem
                                      label="Número de documento"
                                      name="doc_number"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Introduzca el número de documento",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Número de documento" disabled={isAdmin} />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} sm={24}>
                            <FormItem
                              label="Nombre del titular"
                              name="holder_name"
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Introduzca el nombre del titular",
                                },
                              ]}
                            >
                              <Input placeholder="Nombre del titular" disabled={isAdmin} />
                            </FormItem>
                            <Row className="gx-flex-row" style={{marginLeft:-15, marginRight:-16}}>
                              <Col md={16} sm={24}>
                                <FormItem
                                  label="Banco"
                                  name="bank_name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Introduzca el nombre del banco",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Nombre de banco" disabled={isAdmin} />
                                </FormItem>
                              </Col>
                              <Col md={8} sm={24}>
                                <FormItem 
                                  name="is_preferential" 
                                  label="Preferencial"
                                >
                                  <Checkbox 
                                    disabled={isAdmin} 
                                    defaultChecked={formDollar.getFieldValue('is_preferential')}
                                    onChange={(e) => 
                                      formDollar.setFieldsValue({ is_preferential: e.target.checked })
                                    }
                                  ></Checkbox>
                                </FormItem>
                              </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                      
                {!isAdmin && (
                  <Button style={{}} onClick={()=>onSave("USD")} isLoading={loading}>
                    {dollarAccount.id ? "Editar" : "Guardar"}
                  </Button>
                )}
              </>
            )}
          <Table
            className="gx-table-responsive"
            columns={dollarColumns.filter(col => !isAdmin || col.key !== 'action')}
            dataSource={data.accounts_d}
            scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
            pagination={false}
          />
          {!isAdmin && (
            <div className="gx-d-flex gx-justify-content-end">
              <Button 
                onClick={()=> onCreateAccount("USD")} 
                isLoading={loading}
                className="gx-mt-2"
              >
                Añadir cuenta
              </Button>
            </div>
          )}
        </div>
      </TabPane>
      <TabPane tab="Paypal" key="4" style={{ paddig: 0 }}>
        <div>
          {showForm.PAYPAL && (
              <>
                <Form
                  form={formPaypal}
                  className=""
                  layout="vertical"
                  initialValues={paypal}
                >
                    <Row className="gx-flex-row" style={{marginLeft:-16, marginRight:-16}}>
                        <Col md={12} sm={24}>
                            <FormItem
                              name="phone"
                              label="Número de teléfono"
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Introduzca su correo electrónico.",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Número de teléfono"
                                disabled={isAdmin}
                                maxLength={20}
                              />
                            </FormItem>
                            <FormItem
                              label="Usuario"
                              name="holder_name"
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Introduzca su correo electrónico.",
                                },
                              ]}
                            >
                              <Input placeholder="Usuario" disabled={isAdmin} />
                            </FormItem>
                        </Col>
                        <Col md={12} sm={24}>
                            <FormItem
                              label="Correo"
                              name="email"
                              style={{ width: "100%" }}
                              rules={[
                                {
                                  type: "email",
                                  message: "El correo electrónico no es valido.",
                                },
                                {
                                  required: true,
                                  message: "Introduzca su correo electrónico.",
                                },
                              ]}
                            >
                              <Input placeholder="Correo" disabled={isAdmin} />
                            </FormItem>
                            <FormItem 
                              name="is_preferential" 
                              label="Preferencial"
                            >
                              <Checkbox 
                                disabled={isAdmin} 
                                defaultChecked={formPaypal.getFieldValue('is_preferential')}
                                onChange={(e) => 
                                  formPaypal.setFieldsValue({ is_preferential: e.target.checked })
                                }
                              ></Checkbox>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                      
                {!isAdmin && (
                  <Button style={{}} onClick={()=>onSave("PAYPAL")} isLoading={loading}>
                    {paypal.id ? "Editar" : "Guardar"}
                  </Button>
                )}
              </>
          )}
          <Table
            className="gx-table-responsive"
            columns={paypalColumns.filter(col => !isAdmin || col.key !== 'action')}
            dataSource={data.paypal}
            scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
            pagination={false}
          />
          {!isAdmin && (
            <div className="gx-d-flex gx-justify-content-end">
              <Button 
                onClick={()=> onCreateAccount("PAYPAL")} 
                isLoading={loading}
                className="gx-mt-2"
              >
                Añadir cuenta
              </Button>
            </div>
          )}
        </div>
      </TabPane>
    </Tabs>
  );
};

export default PaymentMethods;
