import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { Button, Input, Typography } from "antd";
import { Link, useHistory } from "react-router-dom";
import Map from "../../components/Map/Map";
import { useAuth } from "../../context/auth-context";

const { Text } = Typography;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const SignUp = () => {
  const [phoneError, setPhoneError] = useState("");
  const { isLoading, signUp } = useAuth();
  const [errors, setErrors] = useState([]);
  const [coordinates, setCoordinates] = useState(null);
  const [, setInitialCoordinates] = useState(null);
  const [address, setAddress] = useState(null);
  const history = useHistory();

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    setPhoneError("");
    if (!coordinates) {
      setErrors((prev) => [
        {
          message:
            "Haga click sobre el mapa para indicar la ubicacion del establecimiento.",
        },
      ]);
      return;
    }

    signUp(
      { ...values, ...coordinates },
      () => history.replace("review"),
      () => setPhoneError("El número ingresado no es válido")
    );
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const { latitude, longitude } = coords;
      setInitialCoordinates({ lat: latitude, lng: longitude });
    });
  }, []);

  const getAddress = async ({ lat, lng }) => {
    const { results } = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCfkxFj0YHsve_5ztLxA167Vjm3R86lZtI`
    ).then((resp) => resp.json());

    if (results.length) {
      const hasPlusCode = results[0].address_components[0].types.includes(
        "plus_code"
      );
      let add = "";
      if (hasPlusCode) {
        const code = results[0].address_components[0].long_name;
        add = results[0].formatted_address.split(code)[1];
      } else {
        add = results[0].formatted_address;
      }
      setAddress(add);
    }
  };

  const onMapClick = async ({ lat, lng }) => {
    getAddress({ lat, lng });
    setErrors([]);
    setCoordinates({ lat, lng });
  };

  const validatePassword = (_, value) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.,$#%-_&*+!?])[A-Za-z\d.,$#%-_&*+!?]{8,}$/;
    if (!value) {
      return Promise.reject("¡Por favor ingrese una contraseña!");
    } else if (!regex.test(value)) {
      return Promise.reject(
        "¡La contraseña debe contener al menos 8 caracteres, una letra mayúscula, un número y un carácter especial entre .,$#%-_&*+!?"
      );
    } else {
      return Promise.resolve();
    }
  };

  return (
    <div className="gx-app-login-wrap ">
      <div>
        <div className="gx-app-login-main-content signup_container">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg ">
              <Map onPinLocation={onMapClick} />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              {...formItemLayout}
              form={form}
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem
                name="email"
                label="Correo"
                rules={[
                  {
                    type: "email",
                    message: "El correo electrónico no es valido.",
                  },
                  {
                    required: true,
                    message: "Introduzca su correo electrónico.",
                  },
                ]}
              >
                <Input placeholder="Correo electrónico" />
              </FormItem>
              <FormItem
                label="Nombre"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Introduzca el nombre del establecimiento.",
                  },
                ]}
              >
                <Input placeholder="Nombre del bar" />
              </FormItem>
              <FormItem
                label="Descripción"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Introduzca una descripción.",
                  },
                ]}
              >
                <Input.TextArea
                  autoComplete="off"
                  rows={4}
                  placeholder="Descripción del bar"
                />
              </FormItem>
              <FormItem label="Telefono" required name="phone">
                <Input placeholder="Número de telefono" />
              </FormItem>
              {phoneError && (
                <Text
                  type="danger"
                  style={{
                    marginBottom: 5,
                    marginTop: -10,
                    display: "block",
                    marginLeft: "32%",
                  }}
                >
                  {phoneError}
                </Text>
              )}
              <FormItem
                label="Contraseña"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Introduzca una contraseña",
                  },
                  {
                    validator: validatePassword,
                  },
                ]}
              >
                <Input.Password type="password" placeholder="Contraseña" />
              </FormItem>
              <FormItem
                label="Repetir contraseña"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: "Introduzca una contraseña",
                  },
                  {
                    validator: (_, val) => {
                      if (!val) {
                        return Promise.resolve();
                      }
                      if (val === form.getFieldValue("password")) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Las contraseña no coincide.");
                    },
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Repetir contrasena"
                />
              </FormItem>
              <FormItem label="Direccion">
                <Input placeholder="Direccion" value={address} disabled />
              </FormItem>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  paddingRight: "16px",
                }}
              >
                <Button
                  type="primary"
                  className="gx-ml-auto"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Registrarse
                </Button>
              </div>
            </Form>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5px",
              }}
            >
              <Link
                to="/signin"
                className="gx-ml-2"
                style={{ marginTop: "-10px" }}
              >
                ¿Ya tienes una cuenta?, <strong>Inicia sesión!.</strong>
              </Link>
            </div>
            {errors &&
              errors.map((error, index) => (
                <Text key={`errorLogin${index}`} type="danger">
                  {error.message}
                </Text>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
